import React from 'react';
import styled from 'styled-components';

import {
  ContentfulComponentBackground,
  ContentfulPageProductFeature,
  ContentfulEntry,
} from '@/../graphql-types';
import BackgroundWrapper from '@/components/BackgroundWrapper'; //helper for background css on other components
import {
  HeroBanner,
  PlatformFeatureStats,
  PlatformFeatureCards,
  PlatformFeatureDouble,
  PlatformFeatureLarge,
  PlatformFeatureSet,
  PromoPopUp,
  ResourceCardSet,
  RichText,
  RichTextColumns,
  PlatformCardSet,
  TabbedFeatures,
  Testimonial,
  TextBlock,
  FooterCta,
  TwoColumnRichText,
  Undefined,
  RegistrationForm,
} from '@/components/contentful/components';
import * as Constants from '@/types/Constants';

type BackgroundComponent = ContentfulEntry & {
  background: ContentfulComponentBackground;
};

const Container = styled.div``;

interface PageProductFeatureProps {
  data?: ContentfulPageProductFeature;
}
const PageProductFeature: React.FC<PageProductFeatureProps> = (props) => {
  const { data } = props;

  // if the component has a background, then wrap it with a background
  const wrapBackground = (component, element) => {
    const hasBackground = component?.['background'] != null;
    if (hasBackground) {
      return (
        <BackgroundWrapper
          key={`background-component-${component.id}`}
          data={(component as BackgroundComponent)?.background}
        >
          {element}
        </BackgroundWrapper>
      );
    } else {
      return element;
    }
  };

  return (
    <Container className="">
      {data.heroBanner &&
        wrapBackground(
          data.heroBanner,
          <HeroBanner data={data.heroBanner as ContentfulEntry} />,
        )}

      {data.intro && <TextBlock data={data.intro} />}

      {data.sections?.map((contentfulComponent: ContentfulEntry) => {
        // determine which contentful component should be used
        let element;
        const key = `component-${contentfulComponent.id}`;

        switch (contentfulComponent?.internal?.type) {
          case Constants.COMPONENT_PLATFORM_FEATURE_STATS:
            element = (
              <PlatformFeatureStats key={key} data={contentfulComponent} />
            );
            break;
          case Constants.COMPONENT_PLATFORM_FEATURE_CARDS:
            element = (
              <PlatformFeatureCards key={key} data={contentfulComponent} />
            );
            break;
          case Constants.COMPONENT_PLATFORM_FEATURE_DOUBLE:
            element = (
              <PlatformFeatureDouble key={key} data={contentfulComponent} />
            );
            break;
          case Constants.COMPONENT_PLATFORM_FEATURE_LARGE:
            element = (
              <PlatformFeatureLarge key={key} data={contentfulComponent} />
            );
            break;
          case Constants.COMPONENT_PLATFORM_FEATURE_SET:
            element = (
              <PlatformFeatureSet key={key} data={contentfulComponent} />
            );
            break;
          case Constants.COMPONENT_PROMO_POPUP:
            element = <PromoPopUp key={key} data={contentfulComponent} />;
            break;
          case Constants.COMPONENT_RESOURCE_CARD_SET:
            element = <ResourceCardSet key={key} data={contentfulComponent} />;
            break;
          case Constants.COMPONENT_RICH_TEXT:
            element = <RichText key={key} data={contentfulComponent} />;
            break;
          case Constants.COMPONENT_RICH_TEXT_COLUMNS:
            element = <RichTextColumns key={key} data={contentfulComponent} />;
            break;
          case Constants.COMPONENT_PLATFORM_CARD_SET:
            element = <PlatformCardSet key={key} data={contentfulComponent} />;
            break;
          case Constants.COMPONENT_TABBED_FEATURES:
            element = <TabbedFeatures key={key} data={contentfulComponent} />;
            break;
          case Constants.COMPONENT_TESTIMONIAL:
            element = <Testimonial key={key} data={contentfulComponent} />;
            break;
          case Constants.COMPONENT_TWO_COLUMN_RICHTEXT:
            element = (
              <TwoColumnRichText key={key} data={contentfulComponent} />
            );
            break;
          default:
            element = (
              <Undefined
                key={key}
                message={`ComponentMapper: "${contentfulComponent?.internal?.type}" not defined.`}
              />
            );
        }

        return wrapBackground(contentfulComponent, element);
      })}

      {data?.footerCta && <FooterCta data={data.footerCta} />}
      {data?.contactForm && <RegistrationForm data={data?.contactForm} />}
    </Container>
  );
};
export default PageProductFeature;
